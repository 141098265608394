body {
  margin: 0;
  padding: 0;
  font-family: Segoe UI, Segoe UI Web (West European), Segoe, -apple-system,
    BlinkMacSystemFont, Roboto, Helvetica Neue, Tahoma, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
}

.greco-header__app-launcher {
  display: none;
}
.greco-header__tick {
  display: none;
}

.app-wrapper--panelIsOpen {
    width: calc(100% - 340px);
}

.app-wrapper {
  position: absolute;
  overflow: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;


  background-image: url(./assets/GrECoGraphicTriangles.svg);
  background-size: auto;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
}
.tabTable tbody tr{
border-bottom: 1px solid black;
border-top: 1px solid black;
border-collapse: collapse;
}


.ms-DatePicker-goToday {
  right: 14px;
}

.Toastify__toast-container {
z-index: 123123123123123 !important;
}
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888888; /* Change the thumb color */
  border-radius: 10px;        /* Add rounded corners to the thumb */
}

/* Add a hover effect */
::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa; /* Change the thumb color on hover */
}

.grid-table {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 5 equal columns */
  margin: auto;
}

.grid-header {
  padding: 1px;
  text-align: center;
}

.grid-cell {
  align-items: center; /* Aligns items vertically in the center */
  text-align: center;
}

.grid-cell-insurer {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  text-align: left;
}

.checkbox-label {
  margin-left: 5px; /* Space between the checkbox and the label text */
  white-space: nowrap; /* Ensures the text doesn't wrap */
}

