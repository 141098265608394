.settings {
  &__wrapper {
    padding-left: 16px;
    padding-right: 16px; 
    overflow: hidden;
  }
  &__switch {
    display: flex;

    .bolded {
      margin-left: 10px;
    }
  }
}