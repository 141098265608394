.panel-title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 18px;
  left: 16px;
}

.remove-shadow {
  .ms-Panel-navigation {
    height: 65px;
  }

  .ms-Panel-main{
    box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
    // background-color: #FAF9F8;
  }

  .ms-Panel-content {
    padding: 0;
  }
}