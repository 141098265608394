.user-settings {
  // background: white;
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
  padding: 14px 16px 15px;
  display: flex;
  align-items: middle;
  white-space: nowrap;

  &__image {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  &__details {
    max-width: 215px;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
    display: table-cell;
    vertical-align: middle;

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 16px;
    }
    .email {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .links {
      button, a {
        display: block;
        margin-top: 4px;
        font-weight: 600;
      }
    }
  }

  .ms-Persona-imageArea {
    width: 88px;
    height: 88px;

    .ms-Persona-initials {
      width: 88px;
      height: 88px;
      line-height: 88px;
    }
  }
}