// Dark mode fix
.dark-mode {
  .greco-header {
    &__tick {
      fill: black;
    }
  }

  .greco-header__middle .middle-content__icons .is-checked,
  .greco-header__user.is-checked {
    color: white;
    background: black;
  }

  .greco-header__user:hover {
    background: rgb(21, 21, 21);
  }
}

.greco-header {
  background: #005aa1;
  display: flex;
  height: 50px;
  line-height: 50px;

  &__tick {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    fill: white;
  }

  &__app-launcher {
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    text-align: center;
    height: 100%;
  }

  &__middle {
    display: flex;
    flex: 1 0 auto;
    height: 100%;

    .logo {
      max-height: 44px;
      margin: 2px 1px 1px;
      width: auto;
      padding-left: 5px;
    }

    .additional-logo {
      max-height: 44px;
      padding-right: 10px;
    }

    .middle-content {
      display: flex;
      width: 100%;
      align-items: center;

      &__title {
        justify-content: flex-start;
        display: flex;

        span {
          color: white;
          font-weight: 600;
          font-size: 16px;
          line-height: 50px;
          padding: 0 20px 0 8px;
        }
      }

      .message-count {
        display: block;
        position: absolute;
        right: 7px;
        top: 7px;
        background-color: red;
        color: white;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        font-size: 11px;
        text-align: center;
        line-height: 13px;

        span {
          padding-right: 1px;
        }
      }

      &__search {
        display: flex;
        flex: 1;
        justify-content: center;
      }

      &__icons {
        button {
          border-radius: 0;
        }
        justify-content: flex-end;

        .is-checked {
          color: #106ebe;
          background: white;
        }
      }
    }
  }

  &__user {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;

    &:hover {
      background: #f3f2f1;
    }

    &.is-checked {
      background: white;
    }
  }
}
